import React from "react";
import { WhatsappShareButton } from "react-share";
import { useTranslation } from "react-i18next";
import { BsWhatsapp } from "react-icons/bs";

//-------Create a Deals Header component--------
function WhatsApp(props) {
  const { t } = useTranslation();
  let newContact = props.contact.replaceAll("-", "");
  let replacePlusContact = newContact.replaceAll('+',"");
  newContact = `+${replacePlusContact}`

  return (
    <React.Fragment>
      <a href={`https://wa.me/${newContact}`} className="classified_tag" target="_blank">
      <button className="classiFields_whatAppIcon">
          <span>
            <BsWhatsapp />
            {props.watsApp === true ? (<span>{t("WHATSAPP")}</span>) : <span>{t("WHATSAPP_ME")}</span>}
          </span>
      </button>
      </a>
    </React.Fragment>
  );
}
export default WhatsApp;

